const { compact } = require('lodash')

const categoryMap = {
  necessary: 'C0001',
  performance: 'C0002',
  functional: 'C0003',
  targeting: 'C0004',
  socialmedia: 'C0005',
  youtube: 'P0002',
}

function revisitConsent() {
  if (window.OneTrust) {
    window.OneTrust.ToggleInfoDisplay()
  }
}

function getActiveGroups() {
  if (window.OnetrustActiveGroups) {
    return compact(window.OnetrustActiveGroups.split(','))
  }
  return []
}

function isCategoryConsented(category) {
  const categoryCode = categoryMap[category]
  return getActiveGroups().includes(categoryCode)
}

function getCookieSettingsButtonText() {
  if (isUnderCcpa()) {
    return 'Do Not Sell Or Share My Personal Information'
  }
  return 'Cookie Settings'
}

function isUnderCcpa() {
  // For now we are disabling this the ccpa notif. in favor of having all users same experience
  // but we are keeping the code in case we need to enable it in the future
  // when the responsible authorities makes their mind.
  // if (window.OneTrust) {
  //   const oneTrustDomainData = window.OneTrust.GetDomainData()
  //   return oneTrustDomainData.CookieSettingButtonText.includes('Do Not Sell')
  // }
  return false
}

function isContentAllowed(contentType) {
  switch (contentType) {
    case 'youtube':
      return isCategoryConsented('youtube')
    default:
      return false
  }
}

export {
  isContentAllowed,
  revisitConsent,
  getCookieSettingsButtonText,
  isUnderCcpa,
}
