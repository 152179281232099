import React from 'react'
import Icon from './Icons'
import { revisitConsent } from '../../utils/cookieHelpers'

export function YoutubeBlocked(props) {
  const {
    designModifierClassName,
    simpleNotification,
  } = props

  function handleRevisitConsent(event) {
    event.preventDefault()
    revisitConsent()
  }

  const youtubeCookieCategoryName = 'Youtube Cookies'

  return (
    simpleNotification ? (
      <div className={`youtubeBlocked-container ${designModifierClassName || ''}`}>
        <div className={`youtubeBlocked-content ${designModifierClassName || ''}`}>
          <div className={`youtubeBlocked-icons ${designModifierClassName || ''}`}>
            <Icon name='video' />
          </div>
          <div className={`youtubeBlocked-text ${designModifierClassName || ''}`}>
            <p>
              To display video, please enable the &apos;
              {youtubeCookieCategoryName}
              &apos;
              {' '}
              <a href='/' onClick={handleRevisitConsent}>here</a>
              .
            </p>
          </div>
        </div>
      </div>
    ) : (
      <div className={`youtubeBlocked-container ${designModifierClassName || ''}`}>
        <div className={`youtubeBlocked-content ${designModifierClassName || ''}`}>
          <div className={`youtubeBlocked-icons ${designModifierClassName || ''}`}>
            <Icon name='cookie' />
            <div className={`youtubeImageIcon ${designModifierClassName || ''}`} />
          </div>
          <div className={`youtubeBlocked-text ${designModifierClassName || ''}`}>
            <p>
              We're unable to display Youtube due to your current privacy preferences.
            </p>
          </div>
          <div className={`youtubeBlocked-text ${designModifierClassName || ''}`}>
            <p>
              To make use of video, please enable the &apos;
              {youtubeCookieCategoryName}
              &apos; option in the
              {' '}
              <a href='/' onClick={handleRevisitConsent}>cookies manager</a>
              .
            </p>
          </div>
        </div>
      </div>
    )
  )
}
